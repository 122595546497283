<template>
  <div class="page inPage DropdownMenuPage">
    <div class="position">
      <i class="lee-icon lee-icon-arrow-left" @click="back"></i>
      <div class="title">Collapse</div>
    </div>
    <div class="pageBody">
      <lee-cell-group title="基础用法">
        <lee-collapse v-model="activeNames1">
          <lee-collapse-item title="口水定律" name="1">
            当你红得让人流口水时，关于你的口水就会越来越多。
          </lee-collapse-item>
          <lee-collapse-item title="利用定律" name="2">
            不怕被人利用，就怕你没有被利用的价值。
          </lee-collapse-item>
          <lee-collapse-item title="馅饼定律" name="3">
            当天上掉下馅饼时，就要小心地上也会有个陷阱在等着你。
          </lee-collapse-item>
        </lee-collapse>
      </lee-cell-group>
      <lee-cell-group title="手风琴">
        <lee-collapse v-model="activeNames2" accordion>
          <lee-collapse-item title="口水定律" name="1">
            当你红得让人流口水时，关于你的口水就会越来越多。
          </lee-collapse-item>
          <lee-collapse-item title="利用定律" name="2">
            不怕被人利用，就怕你没有被利用的价值。
          </lee-collapse-item>
          <lee-collapse-item title="馅饼定律" name="3">
            当天上掉下馅饼时，就要小心地上也会有个陷阱在等着你。
          </lee-collapse-item>
        </lee-collapse>
      </lee-cell-group>
      <lee-cell-group title="禁用状态">
        <lee-collapse v-model="activeNames6">
          <lee-collapse-item title="口水定律" name="1">
            当你红得让人流口水时，关于你的口水就会越来越多。
          </lee-collapse-item>
          <lee-collapse-item title="利用定律" name="2" footer="禁" icon="map" disabled>
            不怕被人利用，就怕你没有被利用的价值。
          </lee-collapse-item>
          <lee-collapse-item title="馅饼定律" name="3" disabled>
            当天上掉下馅饼时，就要小心地上也会有个陷阱在等着你。
          </lee-collapse-item>
        </lee-collapse>
      </lee-cell-group>
      <lee-cell-group title="使用插槽">
        <lee-collapse v-model="activeNames3">
          <lee-collapse-item name="1" footer="👍">
            <template #title>
              口水定律
              <lee-icon name="fabulous" style="margin-left: 6px"/>
            </template>
            当你红得让人流口水时，关于你的口水就会越来越多。
          </lee-collapse-item>
          <lee-collapse-item title="利用定律" name="2" icon="sun">
            <template #footer>
              <lee-badge content="hot"></lee-badge>
            </template>
            不怕被人利用，就怕你没有被利用的价值。
          </lee-collapse-item>
          <lee-collapse-item title="馅饼定律" name="3" :is-link="false">
            <template #right-icon>
              <lee-icon name="add"/>
            </template>
            当天上掉下馅饼时，就要小心地上也会有个陷阱在等着你。
          </lee-collapse-item>
        </lee-collapse>
      </lee-cell-group>
       <lee-cell-group title="全部展开与全部切换">
        <lee-collapse v-model="activeNames5" ref="all">
          <lee-collapse-item title="口水定律" name="1" >
            当你红得让人流口水时，关于你的口水就会越来越多。
          </lee-collapse-item>
          <lee-collapse-item title="利用定律" name="2">
            不怕被人利用，就怕你没有被利用的价值。
          </lee-collapse-item>
          <lee-collapse-item title="馅饼定律" name="3">
            当天上掉下馅饼时，就要小心地上也会有个陷阱在等着你。
          </lee-collapse-item>
        </lee-collapse>
        <div style="padding-top: 16px;" class="btns">
          <lee-button type="primary" @click="openAll">全部展开</lee-button>
          <lee-button type="primary" @click="closeAll">全部收起</lee-button>
          <lee-button type="primary" @click="toggleAll">全部切换</lee-button>
        </div>
      </lee-cell-group>
      <lee-cell-group title="CollapseItem 方法">
        <lee-collapse v-model="activeNames4">
          <lee-collapse-item title="口水定律" name="1" ref="t1">
            当你红得让人流口水时，关于你的口水就会越来越多。
          </lee-collapse-item>
          <lee-collapse-item title="利用定律" name="2">
            不怕被人利用，就怕你没有被利用的价值。
          </lee-collapse-item>
          <lee-collapse-item title="馅饼定律" name="3">
            当天上掉下馅饼时，就要小心地上也会有个陷阱在等着你。
          </lee-collapse-item>
        </lee-collapse>
        <div style="padding-top: 16px;" class="btns">
          <lee-button type="primary" @click="exp">口水定律(展开)</lee-button>
          <lee-button type="primary" @click="up">口水定律(收起)</lee-button>
          <lee-button type="primary" @click="toggle">口水定律(切换)</lee-button>
        </div>
      </lee-cell-group>
    </div>
    <div class="pageFooter"><img src="@/assets/logo.png"></div>
  </div>
</template>
<script setup>
import {ref} from 'vue'
//import {showToast} from "@/leeplus";
import {useRouter} from 'vue-router'

const router = useRouter()
const back = () => {
    router.push('/')
}
//
const activeNames1 = ref(['1']);
const activeNames2 = ref('1');
const activeNames3 = ref([]);
const activeNames4 = ref([]);
const activeNames5 = ref([]);
const activeNames6 = ref([]);
const t1 = ref(null)
const exp = () => {
    t1.value.toggle(true)
}
const up = () => {
    t1.value.toggle(false)

}
const toggle = () => {
    t1.value.toggle()
}


const all = ref(null);

const openAll = () => {
    all.value.toggleAll(true);
}
const closeAll = () => {
    all.value.toggleAll(false);
}
const toggleAll = () => {
    all.value.toggleAll();
}


</script>
<style>
.btns .lee-button {
  margin-left: 16px;
}

</style>
